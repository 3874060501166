<template>
  <b-container fluid class="login-2022 mx-auto">
    <b-row class="vh-100" align-v="center">
      <b-col cols="4" class="l-left">
        <b-row class="flex-column h-100 ml-4 mr-5">
          <b-col cols="2" class="d-flex align-items-center">
            <div class="logo-black"> </div>
          </b-col>
          <b-col style="margin-top:-80px;">
            <b-row class="h-100" align-v="center">
              <b-col>
                <h4>Welcome!</h4>
                <h6>{{pageContent[localIntent].welcome}}</h6>
                <ul class="list-unstyled login-highlights mt-3">
                  <li v-for="bullet in pageContent[localIntent].bullets">{{bullet}}</li>
                </ul>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="8" class="l-right" :class="localIntent">
        <b-row class="h-100 align-items-center" align-v="center">
          <div class="flex-column w-100">
            <div class="mx-auto login-action text-center">
              <div class="ee-logo mx-auto"> </div>
              <h3 class="my-2" style="font-size: 24px">{{pageContent[localIntent].loginTitle}}</h3>
              <p v-if="localIntent==='employee'" class="text-center small p-0 m-0 mb-4">First time logging in?  <b-link :to="{ name: 'validateIdentity' }">Create Account</b-link></p>
              <b-form @submit="login" v-on:submit.prevent class="credentials-form" :class="{'larkin-form-dirty': $v.input.$dirty}">
                <div class="invalid-feedback d-block" v-if="formError()">
                  <span v-if="formError.code === 'accountNotInitialized'">
                  Your account needs to be initialized. Please <b-link to="/reinitializeRequest">request initialization</b-link> or contact The Larkin Company for assistance.
                  </span>
                  <span v-else>{{formErrorMessage()}}</span>
                </div>

                <b-form-group label-for="email" class="mb-2">
                  <b-input-group>
                    <b-form-input v-model="$v.input.login.$model" type="text" placeholder="Email Address" @change="clearErrors()" :value="input.login"></b-form-input>
                  </b-input-group>

                  <div class="invalid-feedback d-block" v-if="submitted && $v.input.login.$invalid">
                    <span v-if="!$v.input.login.required">Login is required</span>
                  </div>
                </b-form-group>

                <b-form-group v-if="input.passwordRequired" label-for="password" class="mb-3">
                  <b-input-group>
                    <b-form-input v-model="$v.input.password.$model" type="password" placeholder="Password" @change="clearErrors()"></b-form-input>
                  </b-input-group>
                  <div class="invalid-feedback d-block" v-if="submitted && $v.input.password.$invalid">
                    <span v-if="!$v.input.password.required">Password is required</span>
                  </div>
                </b-form-group>

                <p class="first-time-logging-in">
<!--                  <b-form-checkbox  v-model="$v.input.remember" type="checkbox" @change="rememberMe" style="display: flex">Remember Me</b-form-checkbox>-->
                  <b-link class="muted-link font-weight-normal"  v-if="input.passwordRequired" :to="{ name: 'resetPassword', query: { intent } }">Forgot password?</b-link>
                </p>
                <b-btn :disabled="loggingIn || $v.input.password.$invalid || $v.input.$invalid" type="submit" class="btn-larkin-yellow btn-full p-2">
                  <b-row class="justify-content-center align-items-center">
                    <span class="button-text">Log In   <i v-if="!loggingIn" class="icon icon-arrow-right ml-2"></i></span>
                    <div v-if="loggingIn" v-bind:class="{'local-spinner-border':loggingIn, 'local-spinner-spacer':!loggingIn}"/>
                  </b-row>
                </b-btn>
              </b-form>
              <div v-if="localIntent !== 'employee'"> <p class="client-data mt-3">{{pageContent[localIntent].switchLogin.label}} <b-link :to="{ path: pageContent[localIntent].switchLogin.path }">Click here</b-link></p></div>
            </div>
            <div v-if="localIntent === 'employee'" class="mx-auto employer-action justify-content-center p-2">
              <div class="d-flex align-items-center justify-content-center align-items-center my-2">
                <img class="client-login-icon" src="/img/icons/loginClient.svg" />
                <p style="margin: 0px" class="font-weight-normal">Clients & Employers <b-link class="muted-link font-weight-bold" :to="{ path: pageContent[localIntent].switchLogin.path }">click here</b-link> to Login</p>
              </div>
            </div>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ApiProperties from '../../../src/main/resources/application'
import FormMixin from '@/mixins/formMixin'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'login',
  mixins: [FormMixin],
  props: ['intent'],
  data: function() {
    return {
      input: {
        login: localStorage.username || '',
        password: '',
        passwordRequired: false,
        remember: Boolean(localStorage.getItem("username")) || false
      },
      timeout: null,
      loggingIn: false,

      pageContent: {
        "employee": {
          "switchLogin": {
            "label": "Clients & Employers click here to login",
            "path": "/login/employer"
          },
          "loginTitle": "Absence Management Portal",
          "welcome": "Easily manage your Leave of Absence and Benefit Payments.",
          "bullets": ['Submit all necessary paperwork', 'View payments', 'See scheduled time off']
        },
        "employer": {
          "switchLogin": {
            "label": "Looking to manage your Leave of Absence?",
            "path": "/login/employee"
          },
          "loginTitle": "Client Login",
          "welcome": "Access Data and Reports for your company.",
          "bullets": ['Leave programs', 'Payment registers', 'Check vouchers and more!']
        }

      }
    }
  },
  validations: {
    input: {
      login: {
        required
      },
      password: {
        required: requiredIf((model) => {
          return model.passwordRequired
        })
      }
    }
  },
  computed: {
    localIntent() {
      return this.intent || 'employee'
    }
  },
  watch: {
    intent: function(newVal, oldVal) {
      if (newVal==='employer') {
        this.input.passwordRequired = false
        this.input.password = null
      } else {
        this.input.passwordRequired = true
        this.input.password = ''
      }
    }
  },
  methods: {
    onTimeout: function() {
      this.loggingIn = false;
    },
    rememberMe() {
      localStorage.setItem("username", this.input.login)
      this.input.remember = !this.input.remember
    },
    login: function() {
      if (this.loggingIn) {
        return;
      }

      this.loggingIn = true;
      this.timeout = setTimeout(this.onTimeout, 5000);

      // Update the CSRF if needed before logging in
      this.$root.updateAuthStatus()
          .then(() => {
            const intentObject = this.$constants.UserIntent.getByPath(this.localIntent)
            const requestObj = this.$root.trustDevice ? { trustedAgentUuid: this.$root.trustedAgentUuid, userLevel: intentObject.userType} : {userLevel: intentObject.userType};

            //NOTE: removes any autofill added by the browser when this field is hidden
            if (!this.input.passwordRequired) {
              this.input.password = null
            }
            Object.assign(requestObj, this.input);

            this.submit('post',
                ApiProperties.api.authenticate,
                {},
                requestObj, (response) => {
                  let authStatus = response.data
                  if (authStatus.authTypeRequired==="LOGIN") {
                    this.input.passwordRequired = true
                    this.loggingIn = false
                  } else {
                    this.$root.updateAuthStatus(response.data)
                  }
                },
                () => { // error in authenticate
                  this.loggingIn = false;
                }
            )
          }, (error) => { // error in updateAuthStatus
            console.log("Error: "+JSON.stringify(error))
            this.loggingIn = false;
          });
    }
  },

  created() {
    if (this.intent==='employee') {
      this.input.passwordRequired = true
    }
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  }
}
</script>
<style lang="scss">
@import "../scss/login-pages";

</style>